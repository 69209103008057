import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/templates/single-page.js";
import { graphql } from 'gatsby';
import Video from '../components/video';
export const data = graphql`
  query {
    yoga: file(relativePath: { eq: "sheripeake.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Img = makeShortcode("Img");
const layoutProps = {
  data,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`At the Co-op, we believe that learning happens in many different ways and so we are happy to offer our children special enrichment classes.  These classes offer children new experiences, the chance to explore new skills and the opportunity to learn using all the senses.   Plus they’re a whole lot of fun!`}</p>
    <p>{`In addition to our music together curriculum, we offer yoga and Maestro Tales, a music program that introduces children to classical music and orchestral instruments. In recent years, the Co-op has also offered science, cooking and dance.`}</p>
    <h2>{`Yoga`}</h2>
    <Img fluid={props.data.yoga.childImageSharp.fluid} alt="Yoga instructor Sheri Peake holds a pose." style={{
      marginBottom: '1.75rem'
    }} mdxType="Img" />
    <p>{`Sheri Peake, an instructor at Jaipure Yoga and D`}{`*`}{`Fit Montclair, leads the Co-op’s yoga enrichment classes. Miss Sheri’s empowering classes offer an age appropriate introduction to the world of yoga, positive affirmations, team building and community.`}</p>
    <p>{`Drawing from a variety of poses and using games, chants and music, Miss Sheri gently introduces children to the basic concepts of yoga. The children are free to explore different yoga poses in their own time and the sequences are often driven by the imagination of the children themselves; kids can be animals, dinosaurs, almost anything they can think of!  Breathing exercises are also approached creatively to ensure that every aspect of yoga is presented in a playful and engaging way.  Not only do our students get all of the physical benefits of yoga—the development of gross motor skills, flexibility, body awareness and strength—but they get to have lots of fun too.  Laugh, play, balance and breathe as Miss Sheri redefines “plugging in” and explores with her young students what it truly means to be connected.  `}</p>
    <p>{`Miss Sheri is a 500 hour Power Yoga Instructor holding additional certifications in both yin and children’s yoga. Her love of children and passion for planting early seeds for the healthy development of self has led her to her practice with children and inspired her to create “Little Hands Connected” in 2011, and “Connected Kids Yoga” in 2015.`}</p>
    <p>{`Miss Sheri derives her greatest inspiration from her husband and two children, whom she credits with keeping her grounded, grateful and striving to be her personal best. `}</p>
    <h2>{`MaestroTales`}</h2>
    <Video videoSrcURL="https://www.youtube-nocookie.com/embed/4orUTlYKqec" videoTitle="MaestroTales - The Hello Song" mdxType="Video" />  
    <br />    
A boutique children's program that presents classical music and nursery rhymes, theatrical storytelling of fairytales, and an introduction to symphonic and folk instruments. Their goal is to cultivate musical development during a child's most formative years.
    <p>{`Students get hands-on experience with instruments from every orchestral family, as well as lesser-known folk instruments. The children are introduced to a variety of musical terms throughout the class with the help of sensory aids such as a giant parachute, giant scrunchie, shakers, and rhythm sticks.  A class favorite is bubble music, when they play an extended classical piano excerpt while blowing bubbles!`}</p>
    <p>{`Those that have taken Maestro Tales class not only learn to identify musical instruments and terms but become more familiar with well-known classical music and composers, all while being told a timeless fairytale.`}</p>
    <p>{`The people that bring you MaestroTales every week are professional actors and musicians, who have spent their careers performing around the world.`}</p>
    <h2>{`Literacy`}</h2>
    <p>{`Children are avid story-tellers so the Co-op is excited to be pioneering a new literacy program which embraces this natural excitement and helps our students to develop their emerging language skills such as storytelling, retelling, and book making.`}</p>
    <p>{`This unique initiative was developed by Manhattanville College Professor of Early Childhood Development Victoria Fantozzi, Ph.D., who has been overseeing its use at the Co-op.`}</p>
    <p>{`The program employs Seesaw, a special iPad-based application, which children use to create, narrate, illustrate or even act out their own stories, often about the events of their day at school. Essentially, Seesaw functions as an innovative ‘learning journal,’ and allows students of all ages to capture what they are learning at school using photos, videos, and their own voices.`}</p>
    <p>{`In recent months, the Co-op has used Seesaw to make whole-class books about shared experiences, such as making applesauce, or about the class pet, Pearl the Bunny. We also use it to make individual books, such as All-About-Me books, or to describe a special project.`}</p>
    <p>{`Each child has his or her own Seesaw journal and teachers upload children’s final documents into the Seesaw parent application so that children’s digital portfolios are accessible to their families. Parents are notified (via text message, email or the Seesaw parent app) when new items are added to their child’s Seesaw journal so that they can immediately see and hear what their child did at school that day.`}</p>
    <p>{`Co-op parents love the program because Seesaw provides them with a unique view into their child’s school day. Plus, it gives parents the chance to have deeper conversations with their child about the work they do at the Co-op each day.`}</p>
    <p>{`As digital natives, children are very comfortable with the technology and our teachers ensure that the technology is fully integrated into our play-based curriculum.`}</p>
    <p>{`The Co-op’s literacy program is primarily implemented in our Discovery-Pre-Kindergarten (DPK) classroom, for children ages 3-5 but aspects of the program have also been introduced into our Creative Play classroom for students ages 2-3. `}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      